<template>
  <div class="container">
    <div class="columns">
      <div class="column content section">
        <callout type="success">
          Your invoice has been submitted to the department.<br>
          Your confirmation number is {{ confirmationId }}.<br>
          If you have questions about your submission or the invoice submission process, please contact <a href="mailto:vendorinvoice.questions@phila.gov">vendorinvoice.questions@phila.gov</a>.
        </callout>
      </div>
    </div>
  </div>
</template>

<script>

import { Callout } from '@phila/phila-ui';

export default {
  name: "InvoicesConfirmation",
  components: {
    Callout,
  },
  data() {
    return {
      test: true,
    };
  },
  computed: {
    confirmationId() {
      return this.$store.state.invoices.submittedInvoiceData.humanReadableId;
    },
  },

};

</script>
